import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ScrollAnimation from "react-animate-on-scroll";

const MySwal = withReactContent(Swal);

const alertContent = () => {
  MySwal.fire({
    title: "Congratulations!",
    text: "Your message was successfully sent and will be back to you soon.",
    icon: "success",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

const INITIAL_STATE = {
  fullname: "",
  email: "",
  mobile: "",
  gender: "",
  dob: "",
  city: "",
  pincode: "",
  homeAddress: "",
  test: "",
  medicalPrescription: null,
};

export default function HomeCollection() {
  const [contact, setContact] = useState(INITIAL_STATE);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: files[0] }));
  };

  const onSubmit = async () => {
    try {
      const {
        fullname,
        email,
        mobile,
        gender,
        dob,
        city,
        pincode,
        homeAddress,
        test,
        medicalPrescription,
      } = contact;
      const payload = {
        fullname,
        email,
        mobile,
        gender,
        dob,
        city,
        pincode,
        homeAddress,
        test,
        medicalPrescription,
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      // If you need to send the form with a file (e.g., medical prescription)
      const res = await fetch(
        // "https://server-host.dodahealthcare.com/register",
        "http://localhost:8004/api/contact-us",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await res.json();

      if (data.status === 401 || !data) {
        console.log("error");
      } else {
        setContact(INITIAL_STATE);
        alertContent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="drop-area home-collection-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="drop-item drop-img">
              <div className="drop-left">
                <ScrollAnimation animateIn="fadeInUp" offset="100">
                  <h2>Drop your message for home collection</h2>
                </ScrollAnimation>

                <form id="homeCollectionForm" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Enter your full name"
                          className="form-control"
                          value={contact.fullname}
                          onChange={handleChange}
                          {...register("fullname", {
                            required: "Full name is required",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.fullname?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                          className="form-control"
                          value={contact.email}
                          onChange={handleChange}
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^\S+@\S+$/i,
                              message: "Please enter a valid email address",
                            },
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.email?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="mobile"
                          placeholder="Enter your mobile number"
                          className="form-control"
                          value={contact.mobile}
                          onChange={handleChange}
                          {...register("mobile", {
                            required: "Mobile number is required",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.mobile?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <select
                          name="gender"
                          className="form-control"
                          value={contact.gender}
                          onChange={handleChange}
                          {...register("gender", {
                            required: "Please select your gender",
                          })}
                        >
                          <option value="">Select Your gender</option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                          <option value="other">Other</option>
                        </select>
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.gender?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="date"
                          name="dob"
                          className="form-control"
                          value={contact.dob}
                          onChange={handleChange}
                          {...register("dob", {
                            required: "Please select your date of birth",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.dob?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter your city"
                          className="form-control"
                          value={contact.city}
                          onChange={handleChange}
                          {...register("city", {
                            required: "City is required",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.city?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="pincode"
                          placeholder="Enter your pincode"
                          className="form-control"
                          value={contact.pincode}
                          onChange={handleChange}
                          {...register("pincode", {
                            required: "Pincode is required",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.pincode?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-8">
                      <div className="form-group">
                        <textarea
                          name="homeAddress"
                          cols="30"
                          rows="1"
                          placeholder="Enter your home address"
                          className="form-control"
                          value={contact.homeAddress}
                          onChange={handleChange}
                          {...register("homeAddress", {
                            required: "Home address is required",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.homeAddress?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-8">
                      <div className="form-group">
                        <select
                          name="test"
                          className="form-control"
                          value={contact.test}
                          onChange={handleChange}
                          {...register("test", {
                            required: "Please select a test",
                          })}
                        >
                          <option value="">Select your test</option>
                          <option value="blood-test">Blood Test</option>
                          <option value="urine-test">Urine Test</option>
                          <option value="x-ray">X-Ray</option>
                          <option value="ecg">ECG</option>
                        </select>
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.test?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <i class="icofont-upload-alt"></i>
                        <input
                          type="file"
                          name="medicalPrescription"
                          className="form-control file-upload"
                          onChange={handleFileChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                          {...register("medicalPrescription", {
                            required: "Please upload your medical prescription",
                          })}
                        />
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.medicalPrescription?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-sm-12">
                      <button type="submit" className="drop-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
