import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const ContactInfo = () => {
  return (
    <div className="location-area ptb-70">
      <div className="container">
        <div className="section-title">
          <ScrollAnimation animateIn="fadeInUp" offset="100">
            <h2>Contact Us</h2>
          </ScrollAnimation>
        </div>
        <div className="row location-wrap">
          <div className="col-sm-12 col-lg-4 col-md-4 mb-25">
            <div className="location-item">
              {/* <i className="icofont-location-pin"></i> */}
              <i>
                <img
                  src="/images/google-maps.png"
                  alt="radiology-icon"
                  className="img-fluid"
                />
              </i>

              <h3>Location</h3>
              <p>
                23-B, Pusa Road, Opposite Metro Pillar 115, New Delhi - 110005,
                India.
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-lg-4 col-md-4 mb-25">
            <div className="location-item">
              {/* <i className="icofont-ui-message"></i> */}
              <i>
                <img
                  src="/images/email.png"
                  alt="radiology-icon"
                  className="img-fluid"
                />
              </i>

              <h3>Email</h3>
              <p>
                <a href="mailto:info@dodahealthcare.com">
                  info@dodahealthcare.com
                </a>
                <a href="mailto:feedback@dodahealthcare.com">
                  feedback@dodahealthcare.com
                </a>
                <a href="mailto:appointments@dodahealthcare.com">
                  appointments@dodahealthcare.com
                </a>
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-lg-4 col-md-4 mb-25">
            <div className="location-item">
              {/* <i className="icofont-ui-call"></i> */}
              <i>
                <img
                  src="/images/telephone.png"
                  alt="radiology-icon"
                  className="img-fluid"
                />
              </i>

              <h3>Phone</h3>
              <p>
                <a href="tel:+919643106759">Call: +91 9643106759</a>
              </p>
              <p>
                <a href="tel:+919643106760">Call: +91 9643106760</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
